import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';

export const DIRECTIONS = {
  LTR: 'ltr',
  RTL: 'rtl',
} as const;

export type DIRECTIONS = typeof DIRECTIONS[keyof typeof DIRECTIONS];

export const DirectionContext = React.createContext<{ direction: DIRECTIONS }>({
  direction: DIRECTIONS.LTR,
});

export const DirectionProvider = ({
  direction,
  children,
}: {
  direction: DIRECTIONS;
  children: React.ReactNode;
}) => {
  const value = React.useMemo(() => ({ direction }), [direction]);
  return (
    <DirectionContext.Provider value={value}>
      <div dir={direction}>{children}</div>
    </DirectionContext.Provider>
  );
};

export const withDirection = (WrappedComponent: React.ComponentType<any>) => {
  const WithDirection: React.ComponentType<any> = class extends React.Component<any> {
    static contextType = DirectionContext;
    render() {
      return <WrappedComponent direction={this.context.direction} {...this.props} />;
    }
  };

  if (WrappedComponent.defaultProps) {
    WithDirection.defaultProps = WrappedComponent.defaultProps;
  }
  WithDirection.displayName = `withDirection2(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;

  return hoistNonReactStatics(WithDirection, WrappedComponent);
};
